import { Navigation } from './Navigation'
import { Alerts } from './Alerts'

/**
 * Renders the floating NavBar and Alerts
 */
export const NavBarAndAlerts = ({ children }) => {
  return (
    <>
      {children}
      <Navigation />
      <Alerts />
    </>
  )
}
